
$(function() {
// Datepick








//Ferienwohnungmap
    var map = L.map('fewomap',{"scrollWheelZoom":false,"dragging":true,"tap":true,
        minZoom:8,maxZoom:17,
        maxBounds:[[53,11.5],[55,13.5]]}).setView([54.260, 12.243], 15);
    L.Icon.Default.imagePath = "images/leaflet/"
    L.tileLayer('/tiles/{z}/{x}/{y}.png', {
        detectRetina:true,
        tileSize:256,
        maxNativeZoom:17,
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    map.on('click', function(e) {
       // L.Util.setOptions(e,{"scrollWheelZoom":true});
       map.scrollWheelZoom.enable(); });
    // add a marker in the given location, attach some popup content to it and open the popup

    L.marker([54.2588, 12.2467]).addTo(map)
        .bindPopup('<b style="color:black">Ferienwohnung</b><address style="color:black">Wohnung 13.0.N<br>Strandparkallee<br>18181 Graal Müritz</address><a class="btn btn-primary" href="https://www.google.de/maps/@54.259018,12.2450334,608m/data=!3m1!1e3"  target="_blank">Satellitenansicht</a>');

//Reiseziele Map
var setSlider = function(e){
   var carousel = $('#reise-slider').carousel(e.target.options.slideTarget);
}

var setMarkerAktiv = function(e){
    e.target.setZIndexOffset(1000)
    var ic = e.target.options.icon.options
    var redMarker = L.AwesomeMarkers.icon({
    icon: ic.icon,
    markerColor: 'green',
    prefix:ic.prefix
  });

   e.target.setIcon(redMarker);
   //setSlider(e.target.options.slideTarget);
}

var setMarkerInAktiv = function(e){
    e.target.setZIndexOffset(0)
    var ic = e.target.options.icon.options
    var redMarker = L.AwesomeMarkers.icon({
    icon: ic.icon,
    markerColor: 'blue',
    prefix:ic.prefix
  });

   e.target.setIcon(redMarker);
   //setSlider(e.target.options.slideTarget);
}

$('#reise-slider').on('slid.bs.carousel', function (e) {

  num =  $(".active", e.target).index()
  setMarkerAktiv({target:window.reisemarker[num]})
})
$('#reise-slider').on('slide.bs.carousel', function (e) {
  num =  $(".active", e.target).index()
  setMarkerInAktiv({target:window.reisemarker[num]})
})


var reisemap = L.map('reisezielemap',
    {"tap":true,"scrollWheelZoom":false,
        minZoom:8,maxZoom:17,
        maxBounds:[[53,11.5],[55,13.5]]}).setView([54.260, 12.743], 8);
L.Icon.Default.imagePath = "images/leaflet/"
L.tileLayer('/tiles/{z}/{x}/{y}.png', {
    detectRetina:true,
    tileSize:256,
    maxNativeZoom:17,
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
}).addTo(reisemap);

reisemap.on('click', function(e) {
   // L.Util.setOptions(e,{"scrollWheelZoom":true});
   reisemap.scrollWheelZoom.enable(); });
// add a marker in the given location, attach some popup content to it and open the popup


var activeMarker = L.AwesomeMarkers.icon({
    icon: 'camera-retro',
    markerColor: 'green',
    prefix:"fa",
    zIndexOffset:1000
  });
var inActiveMarker = L.AwesomeMarkers.icon({
    icon: 'camera-retro',
    markerColor: 'blue',
    prefix:"fa",
    zIndexOffset:-1000
  });
window.reisemarker={
    "0":L.marker([54.48081,  12.509308],{icon:activeMarker,zIndexOffset:1000,slideTarget:0}).addTo(reisemap).on('click',setSlider),
    "1":L.marker([54.161931,  12.231903],{icon:inActiveMarker,slideTarget:1}).addTo(reisemap).on('click',setSlider),
    "2":L.marker([54.147808,  12.086163],{icon:inActiveMarker,slideTarget:2}).addTo(reisemap).on('click',setSlider),
    "3":L.marker([54.194383,  12.145729],{icon:inActiveMarker,slideTarget:3}).addTo(reisemap).on('click',setSlider),
    "4":L.marker([54.179014,  12.091484],{icon:inActiveMarker,slideTarget:4}).addTo(reisemap).on('click',setSlider),
    "5":L.marker([53.736567,  11.877863],{icon:inActiveMarker,slideTarget:5}).addTo(reisemap).on('click',setSlider),
    "6":L.marker([54.179416,  12.083416],{icon:inActiveMarker,slideTarget:6}).addTo(reisemap).on('click',setSlider),
    "7":L.marker([54.24256,   12.355499],{icon:inActiveMarker,slideTarget:7}).addTo(reisemap).on('click',setSlider),
    // "8":L.marker([54.181325,  12.086506],{icon:inActiveMarker,slideTarget:8}).addTo(reisemap).on('click',setSlider),
    "8":L.marker([54.351754,  12.389832],{icon:inActiveMarker,slideTarget:9}).addTo(reisemap).on('click',setSlider),
    "9":L.marker([54.320103,  13.044333],{icon:inActiveMarker,slideTarget:10}).addTo(reisemap).on('click',setSlider),
    "10":L.marker([54.091315,  12.132769],{icon:inActiveMarker,slideTarget:11}).addTo(reisemap).on('click',setSlider),
    "11":L.marker([54.078275,  12.091913],{icon:inActiveMarker,slideTarget:12}).addTo(reisemap).on('click',setSlider),
    "12":L.marker([53.78828,   12.224865],{icon:inActiveMarker,slideTarget:13}).addTo(reisemap).on('click',setSlider),
    "13":L.marker([54.141222,  12.554111],{icon:inActiveMarker,slideTarget:14}).addTo(reisemap).on('click',setSlider),
    "14":L.marker([54.573256,  13.663559],{icon:inActiveMarker,slideTarget:15}).addTo(reisemap).on('click',setSlider),
    "15":L.marker([54.20101,   12.302628],{icon:inActiveMarker,slideTarget:16}).addTo(reisemap).on('click',setSlider),
    "16":L.marker([54.315522,  13.09742],{icon:inActiveMarker,slideTarget:17}).addTo(reisemap).on('click',setSlider),
    "17":L.marker([54.240999,  12.432672],{icon:inActiveMarker,slideTarget:18}).addTo(reisemap).on('click',setSlider),
    "18":L.marker([54.448884,  12.588787],{icon:inActiveMarker,slideTarget:19}).addTo(reisemap).on('click',setSlider),
    "19":L.marker([54.064375,  12.722254],{icon:inActiveMarker,slideTarget:20}).addTo(reisemap).on('click',setSlider),
    "20":L.marker([54.1721985,  12.1456164],{icon:inActiveMarker,slideTarget:21}).addTo(reisemap).on('click',setSlider),
}


$('.navbar-custom').on('activate.bs.scrollspy', function () {
 var curScrolPos = $(".nav li.active > a")[0].hash
 history.replaceState(null, "Ostseeurlaub im Strandpark", curScrolPos)
 window.ga && ga('send','pageview',{
  'page':curScrolPos,
  'title':curScrolPos
});

 if (curScrolPos == "#graal"){
    var options = $.lazyLoadXT;
    options.selector =  "iframe[data-scr]"
    options.forceEvent = "lload"
    options.forceLoad = true
    $(window).trigger("lload")
 }
})

})