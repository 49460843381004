/*find prototype*/
if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    'use strict';
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}


var vChapters = [
    {id:"vstep11", duration:39.4, start:0},
    {id:"vstep12", duration:23.9, start:0},
    {id:"vstep13", duration:46.5, start:0},
    {id:"vstep21", duration:72.2, start:0},
    {id:"vstep22", duration:51.1, start:0},
    {id:"vstep23", duration:33.9, start:0},
    {id:"vstep24", duration:27.5, start:0},
    {id:"vstep31", duration:26.3, start:0},
    {id:"vstep32", duration:24.6, start:0},
    {id:"vstep33", duration:96.9, start:0},
    {id:"vstep34", duration:53.4, start:0},
    {id:"vstep41", duration:18.7, start:0},
    {id:"vstep42", duration:42.3, start:0},
    {id:"vstep43", duration:13.3, start:0},
    {id:"vstep44", duration:29.9, start:0},
    {id:"vstep45", duration:1, start:0}
]

var voffset = 0
for(var i=0;i < vChapters.length;i++){
    vChapters[i].start = voffset;
    voffset = voffset + vChapters[i].duration
}


// findChapter("vstep1") -> {id, duration}
vChapters.findChapter =  function(id){
    return vChapters.find(function(chapter){
        return chapter.id === id;
    })
}

// findChapter(3) -> {id, duration}
vChapters.findChapterForPosition =  function(start){
    var startingChaptersBeforePosition = vChapters.filter(function(chapter){
        return chapter.start <= start;
    })
    return startingChaptersBeforePosition.pop();
}

window.toPosition = function(stepEl){
    document.getElementById("xplain").currentTime = vChapters.findChapter(stepEl.id).start;
}

// Assign an ontimeupdate event to the <video> element, and execute a function if the current playback position has changed
document.getElementById("xplain").ontimeupdate = function() {
    var timestamp = document.getElementById("xplain").currentTime
    $(".steps .list-group").children().removeClass("active");
    var chapterId = vChapters.findChapterForPosition(timestamp).id
    $(document.getElementById(chapterId)).addClass("active");
};
